import AesScript from "./AesScript";
const AsyncStorage = {
  setItem(key, value) {
    if (value) {
      localStorage.setItem("encrypted", true);
      value = AesScript.encrypt(key, value);
    }
    return localStorage.setItem(key, value);
  },
  getItem(key) {
    let value = localStorage.getItem(key);
    if (key !== "encrypted" && value) {
      let encrypted = localStorage.getItem("encrypted");
      if (encrypted) {
        value = AesScript.decrypt(key, value);
      }
    }
    return value;
  },
  clear() {
    return localStorage.clear();
  }
};

export default AsyncStorage;
