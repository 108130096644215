
const initialState = {
    location: [],
    errorMessage: ""
};
const alarmReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LOCATION_DATA':
            return { ...state };
        case 'SET_LOCATION_DATA':
            return { ...state, location: action.location };
        case 'LOCATION_REQUEST_FAILED':
            let { errorMessage = "" } = action;
            return { ...state, errorMessage }
        default:
            return state;

    }
};
export default alarmReducer;