import axios from "axios";
import { SERVER_URL, UPLOAD_URL, SERVER_URL1, DOWNLOAD_URL } from "./config";
import { getEncryptedParams } from "./encryption/Encryption";
import { getDecryptedParams } from "./decryption/Decryption";
let key;
const callService = async (params) => {
  try {
    const { postUrl, query } = await parseQuery(params);
    const response = await loadData({ SERVER_URL: postUrl, query });
    return parseResponse({ response });
  } catch (error) {
    console.log("error in call service>>>>>", error.stack);
    throw error;
  }
};

const uploadService = async (params) => {
  try {
    const response = await axios.post(UPLOAD_URL, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response.data.response;
  } catch (error) {
    console.log("error in upload service>>>>>", error.stack);
    throw error;
  }
};

export const getS3ImageUrl = ({ key, name }, token) => {
  return `${DOWNLOAD_URL}/download/s3/?inline=true&token=${token}&fileKey=${key}&fileName=${name}`;
};

export const getS3PublicImageUrl = ({ key }) => {
  return `${DOWNLOAD_URL}/downloadImage/?fileKey=${key}`;
};

const parseResponse = ({ response }) => {
  if (response && response.data && response.data.response && key && key.p32) {
    response.data.response = getDecryptedParams(
      response.data.response,
      key.p32
    );
  }
  return response;
};
const loadData = async ({ SERVER_URL, query }) => {
  try {
    const response = await axios.post(SERVER_URL, query);
    return response;
  } catch (error) {
    throw error;
  }
};
const parseQuery = async (params) => {
  let postUrl = SERVER_URL;
  let query = params;
  if (!key || !key.p21 || !key.p32) {
    let result = await loadData({
      SERVER_URL: `${SERVER_URL1}/loadInfo`,
      query: {},
    });
    // console.log("result>>>>>>>", JSON.stringify(result));
    if (
      result &&
      result.data &&
      result.data.response &&
      result.data.response.result
    ) {
      key = result.data.response.result;
    }
    // console.log("key>>>>>>>>>>>>>", JSON.stringify(key));
    if (!key.p21 || !key.p32) {
      throw new Error("Contact with Admin");
    }
  }
  query.encrypted = true;
  query = getEncryptedParams(query, key.p21);
  return { postUrl, query };
};
const callService1 = (query) => {
  // const response = await axios.post(SERVER_URL, query);
  // return response;
  return new Promise(function (resolve, reject) {
    axios
      .post(SERVER_URL1, query)
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};
export { callService, callService1, uploadService };
