const initialState = {
  data: [],
  sortQuery: { alarm_raise_on: -1 },
  addOnFilter: {},
  count: 0,
  value: "",
  location: {},
  loading: true,
  errorMessage: "",
};
const alarmReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALARM_DATA": {
        return { ...state, loading: true };
    }
    case "UPDATE_ALARM_DATA": {
      const newState = {
        ...state,
        data: action.data,
        count: action.count,
        addOnFilter: action.addOnFilter,
        sortQuery: action.sortQuery,
        loading: false,
      };
      if (action.value || action.value === "") {
        let { value } = action;
        newState.value = value;
      }
      if (action.value || action.value === "") {

        return { ...newState,  count: action.count, loading: false };
    }
    else {

        return { ...newState, count: action.count, loading: false };
    }
    }
    case "UPDATE_SORT_QUERY":
      return { ...state, sortQuery: action.payload.sortQuery };
    case "APPLY_FILTER":
      return { ...state, addOnFilter: action.payload.addOnFilter };
    case "ALARM_REQUEST_FAILED":
      let { errorMessage = "" } = action;
      return { ...state, errorMessage };
    default:
      return state;
  }
};
export default alarmReducer;
