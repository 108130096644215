import { put, takeLatest, all } from 'redux-saga/effects';
import _ from "lodash"
import { errorFunction } from "../../utilities/errorFunction"
import { callService } from "../../utilities/callService"
import AsyncStorage  from "../../utilities/encryption/AsyncStorage";
function* fetchScheduleData(payload)
{
    try
    {
        let { addOnFilter = {} } = payload.payload;
        let query = {
            id: "_find",
            paramValue: {
                "model":"UpdateApp",
                "query":{"id":"appVersionsAvilable"}
            },
            token: AsyncStorage.getItem("token")
        };
       
        let data = [];
        let newData=[];
        const response = yield callService(query);
        data = _.get(response, "data.response.result", []);
        data.map(item =>
            {
                let { _id = "", platform = "", version = "", isNeeded = ""} = item;
                let dataJson = {
                    id: _id,platform, version, isNeeded
                }
                newData.push(dataJson);
            })
        yield put({ type: "UPDATE_APP_VERSION_DATA", data: newData, errorMessage: "" });
    }
    catch (error)
    {
        // let errorMessage = errorFunction(error);
        // yield put({ type: "SCHEDULE_REQUEST_FAILED", errorMessage });
    }
}

function* actionWatcher()
{
    yield takeLatest('GET_APP_VERSION', fetchScheduleData)
}

export default function* rootSaga()
{
    yield all([
        actionWatcher()
    ]);
}