import { combineReducers } from "redux";
import alarmReducer from "./alarmReducer";
import locationReducer from "./locationReducer";
import userReducer from "./userReducer";
import scheduleReducer from "./scheduleReducer";
import alarmDetailReducer from "./alarmDetailReducer";
import appInfoReducer from "./appInfoReducer";
import notificationReducer from "./notificationReducer";
import appVersionReducer from './appVersionReducer';
const appReducer = combineReducers({
  alarmReducer,
  locationReducer,
  userReducer,
  scheduleReducer,
  alarmDetailReducer,
  appInfoReducer,
  notificationReducer,
  appVersionReducer
});
const rootReducer = (state, action) => {
  if (action.type === "RESET_REDUX") {
    state = {
      appInfoReducer: state.appInfoReducer,
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
