import { put, takeLatest, all } from "redux-saga/effects";
import { callService } from "../../utilities/callService";
import { queryBuilder } from "../../utilities/queryBuilder";
import AsyncStorage from "../../utilities/encryption/AsyncStorage";
import _ from "lodash";
import { errorFunction } from "../../utilities/errorFunction";
function* fetchNotificationData(payload) {
  const { limit = 10, skip = 0 } = payload.payload;
  try {
    let query = queryBuilder("_find", "getNotifications", "App_Notification", {
      addOnFilter: {
        user: { _id: AsyncStorage.getItem("userID") },
        chatMessage: { $exists: false },
      },
      skip: skip,
      limit: limit,
      sort: { _createdOn: -1 },
      metadata: true,
    });
    let data = {};
    const response = yield callService(query);
    data = _.get(response, "data.response", {});
    yield put({ type: "UPDATE_NOTIFICATION_DATA", data });
  } catch (error) {
    yield put({
      type: "NOTIFICATION_UPDATE_FAIL",
      errorMessage: errorFunction(error),
    });
  }
}

function* getUnreadNotificationCount() {
  try {
    let query = queryBuilder(
      "_find",
      "getAllNotificationCount",
      "App_Notification",
      {
        addOnFilter: {
          user: { _id: AsyncStorage.getItem("userID") },
          read: false,
        },
        sort: { _createdOn: -1 },
        metadata: true,
      }
    );
    const response = yield callService(query);
    const data = _.get(response, "data.response", {});
    yield put({ type: "UPDATE_UNREAD_NOTIFICATIONS_COUNT", data });
  } catch (error) {
    yield put({
      type: "NOTIFICATIONS_COUNT_FAIL",
      errorMessage: errorFunction(error),
    });
  }
}

function* notificationWatcher() {
  yield takeLatest("GET_NOTIFICATION_DATA", fetchNotificationData);
}

function* undreadNotificaitionsCountWatcher() {
  yield takeLatest(
    "GET_UNREAD_NOTIFICATIONS_COUNT",
    getUnreadNotificationCount
  );
}

export default function* rootSaga() {
  yield all([notificationWatcher(), undreadNotificaitionsCountWatcher()]);
}
