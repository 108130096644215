
const initialState = {
    data: [],
    scheduleErrorMessage: "",
    saveScheduleErrorMessage: ""
};
const scheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SCHEDULE_DATA':
            return { ...state, loading: true };
        case 'UPDATE_SCHEDULE_DATA':
            {
                return { ...state, data: action.data, saveScheduleErrorMessage: "" };
            }
        case 'SCHEDULE_REQUEST_FAILED':
            {
                let { errorMessage = "" } = action;
                return { ...state, scheduleErrorMessage: errorMessage }
            }
        case 'SAVE_SCHEDULE_REQUEST_FAILED':
            let message = action.errorMessage
            return { ...state, saveScheduleErrorMessage: message }
        default:
            return state;
    }
};
export default scheduleReducer;