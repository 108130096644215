//......Password-Based Key Derivation Function (PBKDF) is used to generate key 128-bit (16 bytes), 192-bit (24 bytes) or 256-bit (32 bytes)
import CryptoJS from "crypto-js";
const keySize = 256;
const ivSize = 128;
const iterations = 10;
const AesScript = {
  encrypt: (secretKey, msgToencrypt) => {
    let transitmessage = "";
    if (msgToencrypt && secretKey) {
      let salt = CryptoJS.lib.WordArray.random(ivSize / 8);
      //converted as wordArray encoded as UTF-8.
      let key = CryptoJS.PBKDF2(secretKey, salt, {
        keySize: keySize / 32,
        iterations: iterations
      });
      let iv = CryptoJS.lib.WordArray.random(ivSize / 8);
      let encrypted = CryptoJS.AES.encrypt(msgToencrypt, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      });
      // salt, iv will be hex 32 in length
      // append them to the ciphertext for use  in decryption
      transitmessage = salt.toString() + iv.toString() + encrypted.toString();
    }

    return transitmessage;
  },
  decrypt: (secretKey, msgTodecrypt) => {
    let decrypted = "";
    if (msgTodecrypt && secretKey) {
      let salt = CryptoJS.enc.Hex.parse(msgTodecrypt.substr(0, 32));
      let iv = CryptoJS.enc.Hex.parse(msgTodecrypt.substr(32, 32));
      let encrypted = msgTodecrypt.substring(64);
      let key = CryptoJS.PBKDF2(secretKey, salt, {
        keySize: keySize / 32,
        iterations: iterations
      });

      decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      });
    }

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
};

export default AesScript;
