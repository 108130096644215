import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import React from "react";
import { HashRouter } from "react-router-dom";
// import createSagaMiddleware from 'redux-saga';
// import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import rootSaga from "./redux/sagas";
import ReactDOM from "react-dom";
// import { logger } from 'redux-logger';
import "./index.css";
import App from "./App";
// import reducer from "./redux/reducers"
import store from "./redux/Store";
import * as serviceWorker from "./serviceWorker";
// const sagaMiddleware = createSagaMiddleware();
// const store = createStore(
//     reducer,
//     applyMiddleware(sagaMiddleware, logger),
// );
// sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
